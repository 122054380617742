import React from 'react'
import styled, { css } from 'styled-components'
import { darken } from 'polished'

import TrinaImage from './TrinaImage'

import { ORDER_URL, DONATE_URL } from '../../lib/constants'

export default function HomeHero() {
  return (
    <Section>
      <Container>
        <ImageWrapper>
          <TrinaImage />
        </ImageWrapper>
        <ContentWrapper>
          <Title>About the Author</Title>
          <Description>
            Trina Vargo is the president of the US-Ireland Alliance, an
            organization she founded in 1998. From late 1987 until April 1998,
            she was employed by Senator Edward M. Kennedy and served as his
            foreign policy adviser during the critical years of the Northern
            Ireland peace process.
          </Description>
          <Description>
            Vargo created the US-Ireland Alliance to build ties between the US
            and the island of Ireland for future generations. She also created
            the George J. Mitchell Scholarship program, which sends future
            American leaders to institutions of higher learning on the island of
            Ireland for a year of graduate study. More than three hundred
            Americans apply annually for one of the twelve prestigious
            scholarships. There are now more than two hundred Mitchell Scholars.
            She created the annual Oscar Wilde Awards to honor the Irish in
            entertainment. The annual event is currently held at J.J. Abrams’s
            Bad Robot production company in Santa Monica, and Vargo has played
            an important role in encouraging film production in Ireland,
            including the filming of Star Wars: The Force Awakens on the island
            of Skellig Michael. Vargo has addressed the Joint Committee on
            Foreign Affairs in the Oireachtas (the Irish parliament).
          </Description>
          <Description>
            Working directly with political leaders in Northern Ireland, the
            Clinton Administration, and the Irish Government, Vargo served as a
            key behind-the-scenes player in the Northern Ireland peace process,
            including the granting of a visa for Sinn Fein leader Gerry Adams to
            visit the United States in 1994. That visa was central to the
            subsequent IRA cease-fire and the peace talks which led to the 1998
            Belfast Agreement. As a foreign policy adviser to Kennedy, Vargo’s
            wide-ranging portfolio included imposing sanctions on Libya
            following the 1988 bombing of Pan Am flight 103; spearheading the
            efforts to bring free and fair elections to Guyana in 1992; changing
            the way the State Department analyzed United Nations voting
            patterns, which impacted foreign aid eligibility; and getting Jews
            out of the Soviet Union and Syria, and Americans out of China after
            Tiananmen Square.
          </Description>
          <Description>
            Vargo also served as the adviser on Irish issues to the campaigns of
            every Democratic nominee for president from Michael Dukakis through
            Barack Obama. She advised several American ambassadors to Ireland
            going back to the mid-1990s when Jean Kennedy Smith was nominated as
            President Clinton’s ambassador. She also served as an adviser to
            Madeleine Albright when she was preparing for her Senate
            confirmation hearings to become the US ambassador to the UN.
          </Description>
          <Description>
            With the achievement of the Northern Ireland peace agreement and the
            massive economic advancement of Ireland in the 1990s, Vargo
            recognized how the relationship between the United States and the
            island of Ireland would naturally change, something she first wrote
            about in the Washington Post on the launch of the US-Ireland
            Alliance in 1998.
          </Description>
          <Description>
            In addition to the Mitchell Scholarship program and the Oscar Wilde
            Awards, the Alliance has held golf tournaments in Ireland to
            introduce leading American executives to their Irish counterparts,
            resulting in business for both countries. The Alliance also provides
            materials for high school teachers to teach about Ireland.
          </Description>
          <Description>
            In 2007, Vargo wrote a prescient piece on immigration reform as it
            pertained to the Irish. While it made her the bête noire of a small
            number of vocal individuals, her article was broadly welcomed,
            including by the former Irish Prime Minister Garrett Fitzgerald,
            politicians, business leaders, emigration experts, and immigration
            leaders. A few months after her piece appeared in the Irish Times,
            Irish Prime Minister Bertie Ahern articulated similar views when he
            visited the United States.
          </Description>
          <Description>
            Born and raised in central Pennsylvania, Vargo graduated from the
            University of Pittsburgh with degrees in political science and
            history. As the recipient of a Rotary International Fellowship, she
            attended McGill University in Montreal, where she obtained an MA
            degree in political science with a concentration in international
            relations.
          </Description>
          <ButtonGroup>
            <ButtonWrapper>
              <Button
                color="#008b27"
                href={ORDER_URL}
                rel="noopener noreferrer"
                target="_blank"
              >
                order now
              </Button>
              <Caption>Available now on Amazon</Caption>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button
                color="#005819"
                href={DONATE_URL}
                rel="noopener noreferrer"
                target="_blank"
              >
                donate
              </Button>
              <Caption>to the US-Ireland Alliance</Caption>
            </ButtonWrapper>
          </ButtonGroup>
        </ContentWrapper>
      </Container>
    </Section>
  )
}

const Section = styled.section`
  background-color: #f1f6ef;
`

const Container = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding: 100px 20px;
  @media (min-width: 768px) {
    align-items: flex-start;
    display: flex;
    justify-content: space-evenly;
  }
`

const ImageWrapper = styled.div`
  max-width: 350px;
  @media (max-width: 767px) {
    margin: 0 auto 30px auto;
  }
  @media (min-width: 768px) {
    width: 50%;
    margin-right: 20px;
  }
`

const ContentWrapper = styled.div`
  max-width: 480px;
  text-align: center;
  @media (max-width: 767px) {
    margin: 0 auto;
  }
  @media (min-width: 768px) {
    text-align: left;
  }
`

const Title = styled.h1`
  color: #007e22;
  font-size: 22px;
  font-weight: normal;
  margin-bottom: 1rem;
  margin-top: 0;
`

const Description = styled.p`
  color: #000;
  font-family: 'Cardo';
  &:last-child {
    margin-bottom: 36px;
  }
`

const ButtonGroup = styled.div`
  display: flex;
  @media screen and (max-width: 767px) {
    align-items: center;
    flex-direction: column;
  }
`

const ButtonWrapper = styled.div`
  text-align: center;
  @media (max-width: 767px) {
    &:first-child {
      margin-bottom: 20px;
    }
  }
  @media (min-width: 768px) {
    &:first-child {
      margin-right: 30px;
    }
  }
`

const Button = styled.a`
  align-items: center;
  border-radius: 2px;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  font-family: 'Nunito Sans', 'sans-serif';
  height: 40px;
  justify-content: center;
  margin-bottom: 10px;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 200ms ease;
  width: 200px;
  ${({ color }) => css`
    background-color: ${color};
    &:hover {
      background-color: ${darken(0.025, color)};
    }
  `}
`

const Caption = styled.span`
  color: #007e22;
  font-family: 'Cardo';
  font-size: 14px;
  font-style: italic;
`
